var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CContainer',{staticClass:"d-flex align-items-center min-vh-100"},[_c('CRow',{staticClass:"w-100 justify-content-center"},[_c('CCol',{attrs:{"md":"8"}},[_c('CCard',{staticClass:"p-4"},[_c('CCardBody',[_c('CForm',{attrs:{"was-validated":_vm.wasValidated,"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_c('h1',[_vm._v("Відновлення пароля")]),(_vm.msg.show && _vm.msg?.message !== 'TokenExpired')?_c('CAlert',{staticStyle:{"font-size":"12px"},attrs:{"show":"","color":_vm.msg.color}},[_vm._v(" "+_vm._s(_vm.validationMessage[_vm.msg?.message] || _vm.msg?.message)+" ")]):_vm._e(),(_vm.msg.show && _vm.msg?.message === 'TokenExpired')?_c('CAlert',{staticStyle:{"font-size":"12px"},attrs:{"show":"","color":_vm.msg.color}},[_vm._v(" Це посилання для відновлення паролю вже недійсне. Якщо Ви хочете повторно відновити пароль, скористайтеся "),_c('CLink',{attrs:{"to":"/reset"}},[_vm._v("формою для відновлення паролю.")])],1):_vm._e(),(_vm.msg?.message !== 'TokenExpired')?[_c('p',{staticClass:"text-muted"},[_vm._v("Введіть новий пароль")]),_c('div',{staticClass:"form-item mb-3",class:{'errorInput': _vm.$v.password.$error}},[_c('CInput',{staticClass:"mb-1",class:{
                    'error': _vm.$v.password.$error
                  },attrs:{"placeholder":"Пароль","type":_vm.passwordFieldIdType,"required":"","autocomplete":"new-password"},on:{"input":function($event){return _vm.$v.password.$touch()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.reset.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-lock-locked"}})]},proxy:true},{key:"append-content",fn:function(){return [_c('a',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                  content: 'Показати/сховати пароль',
                                  placement: 'top'
                              }),expression:"{\n                                  content: 'Показати/сховати пароль',\n                                  placement: 'top'\n                              }"}],on:{"click":_vm.showPass}},[_c('CIcon',{attrs:{"name":"cil-low-vision"}})],1)]},proxy:true}],null,false,4117634997),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(!_vm.$v.password.minLength || !_vm.$v.password.required)?_c('small',{staticClass:"form-text errorText w-100"},[_vm._v("Мінімалька к-сть символів "+_vm._s(_vm.$v.password.$params.minLength.min)+".")]):_vm._e()],1),_c('div',{staticClass:"form-item mb-4",class:{'errorInput': _vm.$v.confirm_password.$error}},[_c('CInput',{staticClass:"mb-1",class:{
                "error": _vm.$v.confirm_password.$error
                },attrs:{"placeholder":"Повторити пароль","type":_vm.passwordFieldIdType,"autocomplete":"new-password","required":""},on:{"input":function($event){return _vm.$v.confirm_password.$touch()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.reset.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-lock-locked"}})]},proxy:true},{key:"append-content",fn:function(){return [_c('a',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                  content: 'Показати/сховати пароль',
                                  placement: 'top'
                              }),expression:"{\n                                  content: 'Показати/сховати пароль',\n                                  placement: 'top'\n                              }"}],on:{"click":_vm.showPass}},[_c('CIcon',{attrs:{"name":"cil-low-vision"}})],1)]},proxy:true}],null,false,4117634997),model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}}),(!_vm.$v.confirm_password.minLength || !_vm.$v.confirm_password.required)?_c('small',{staticClass:"form-text errorText w-100"},[_vm._v("Мінімалька к-сть символів "+_vm._s(_vm.$v.confirm_password.$params.minLength.min)+".")]):_vm._e(),(!_vm.$v.confirm_password.sameAs)?_c('small',{staticClass:"form-text errorText w-100"},[_vm._v("Паролі не співпадають.")]):_vm._e()],1),_c('CRow',[_c('CCol'),_c('CCol',{staticClass:"text-right",attrs:{"col":"12","sm":"6","lg":"4"}},[(_vm.loading)?_c('CButton',{staticClass:"btn-block",attrs:{"color":"primary","disabled":""}},[_c('div',{staticClass:"align-items-center"},[_c('CSpinner',{attrs:{"color":"default","size":"sm"}})],1)]):_c('CButton',{staticClass:"px-4 btn-block",attrs:{"color":"primary"},on:{"click":_vm.reset}},[_vm._v(" Відновити ")])],1)],1)]:_vm._e()],2)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }