<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="8">
        <CCard class="p-4">
          <CCardBody>
            <CForm
                :was-validated="wasValidated"
                @submit.prevent="reset"
                novalidate
            >
              <h1>Відновлення пароля</h1>
              <CAlert v-if="msg.show && msg?.message !== 'TokenExpired'" style="font-size: 12px;" show
                      :color="msg.color">
                {{ validationMessage[msg?.message] || msg?.message }}
              </CAlert>
              <CAlert v-if="msg.show && msg?.message === 'TokenExpired'" style="font-size: 12px;" show
                      :color="msg.color">
                Це посилання для відновлення паролю вже недійсне. Якщо Ви хочете повторно відновити пароль,
                скористайтеся
                <CLink to="/reset">формою для відновлення паролю.</CLink>
              </CAlert>
              <template v-if="msg?.message !== 'TokenExpired'">
                <p class="text-muted">Введіть новий пароль</p>
                <div class="form-item mb-3" :class="{'errorInput': $v.password.$error}">
                  <CInput
                      placeholder="Пароль"
                      :type="passwordFieldIdType"
                      required
                      class="mb-1"
                      autocomplete="new-password"
                      @keyup.enter.native="reset"
                      @input='$v.password.$touch()'
                      :class=" {
                      'error': $v.password.$error
                    }"
                      v-model="password"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked"/>
                    </template>
                    <template #append-content>
                      <a @click="showPass" v-c-tooltip="{
                                    content: 'Показати/сховати пароль',
                                    placement: 'top'
                                }">
                        <CIcon name="cil-low-vision"/>
                      </a>
                    </template>
                  </CInput>
                  <small v-if="!$v.password.minLength || !$v.password.required" class="form-text errorText w-100">Мінімалька
                    к-сть символів {{ $v.password.$params.minLength.min }}.</small>
                </div>
                <div class="form-item mb-4" :class="{'errorInput': $v.confirm_password.$error}">
                  <CInput
                      placeholder="Повторити пароль"
                      :type="passwordFieldIdType"
                      autocomplete="new-password"
                      class="mb-1"
                      required
                      @keyup.enter.native="reset"
                      @input="$v.confirm_password.$touch()"
                      :class='{
                  "error": $v.confirm_password.$error
                  }'
                      v-model="confirm_password"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked"/>
                    </template>
                    <template #append-content>
                      <a @click="showPass" v-c-tooltip="{
                                    content: 'Показати/сховати пароль',
                                    placement: 'top'
                                }">
                        <CIcon name="cil-low-vision"/>
                      </a>
                    </template>
                  </CInput>
                  <small v-if="!$v.confirm_password.minLength || !$v.confirm_password.required"
                         class="form-text errorText w-100">Мінімалька
                    к-сть символів {{ $v.confirm_password.$params.minLength.min }}.</small>
                  <small v-if="!$v.confirm_password.sameAs" class="form-text errorText w-100">Паролі не
                    співпадають.</small>
                </div>
                <CRow>
                  <CCol></CCol>
                  <CCol col="12" sm="6" lg="4" class="text-right">
                    <CButton
                        v-if="loading"
                        color="primary"
                        class="btn-block "
                        disabled
                    >
                      <div class="  align-items-center">
                        <CSpinner color="default" size="sm"/>
                      </div>
                    </CButton>
                    <CButton
                        v-else
                        color="primary"
                        @click="reset"
                        class="px-4 btn-block"
                    >
                      Відновити
                    </CButton>
                  </CCol>
                </CRow>
              </template>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import {minLength, required, email, sameAs} from "vuelidate/lib/validators";
import {recoveryPass} from '../../api/recoveryPassword';
import {routerGoToName} from "@/utils/routerUtils";

export default {
  name: "RecoveryPassword",
  data() {
    return {
      url: "",
      loading: false,
      wasValidated: false,
      email: "",
      hash: "",
      password: "",
      confirm_password: "",
      msg: {
        show: false,
        color: '',
        message: ''
      },
      // currentAlertCounter: 0,
      passwordFieldIdType: 'password',
      validationMessage: {
        'done': 'Пароль був успішно змінений!'
      },
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    confirm_password: {
      required,
      minLength: minLength(6),
      sameAs: sameAs('password')
    },
    email: {required, email},
    hash: {required}
  },
  methods: {
    showPass() {
      this.passwordFieldIdType = this.passwordFieldIdType === 'password' ? 'text' : 'password';
    },
    callbackResetPass(resp) {
      this.loading = false;
      if (resp.message === 'done') {
        this.msg = {color: 'success', message: resp.message, show: true}
        setTimeout(() => routerGoToName('Login'), 3000)
        // setTimeout(() => this.$router.push({path: `/login`}), 3000)
        return
      }
      this.msg = {color: 'danger', message: resp.message, show: true}
    },
    reset() {
      this.hash = new URL(location.href).searchParams.get('hash')
      this.email = new URL(location.href).searchParams.get('email')

      this.$v.$touch();
      if (!this.$v.$invalid) {

        this.loading = true;

        return recoveryPass({
          hash: this.hash,
          email: this.email,
          password: this.confirm_password,
        }, callback => this.callbackResetPass(callback));
      }
      if (this.$v.email.$error) return this.msg = {color: 'danger', message: 'В URL відсутній Email.', show: true};
      if (this.$v.hash.$error) return this.msg = {color: 'danger', message: 'В URL відсутній Hash.', show: true};
      return this.wasValidated = true;
    },
  },
}
</script>
<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #ff002a !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: .3rem;
}
</style>
