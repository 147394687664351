import axios from 'axios';

const APIRecoveryPassword = async (body) => {
    try {
        return await axios.post('/api/account/password/new', body)
    } catch (e) {
        return e.response.data;
    }
}

export const recoveryPass = async (body, callback_func) => {
    const response = await APIRecoveryPassword(body);

    if (response.status === 200) return callback_func({message: 'done'});
    callback_func(response);
}
